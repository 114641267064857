var render = function render(){
  var _this = this;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    staticClass: "px-5 ml-5",
    attrs: {
      "disabled": _vm.disabled,
      "flat": ""
    }
  }, [_c('v-row', [_c('v-col', {
    staticClass: "col-12 col-md-6"
  }, [_c('v-card-title', [_vm._v(_vm._s(_vm.$t('EditBrandInformations')))]), _c('v-card-text', [_c('v-form', {
    ref: "brandForm"
  }, [_vm.brand ? _c('v-text-field', {
    attrs: {
      "label": _vm.$t('name'),
      "rules": [function (value) {
        return !!value.trim() || _this.$t('nameRules');
      }],
      "outlined": "",
      "required": "",
      "loading": _vm.loading,
      "disabled": _vm.loading || _vm.brandPurchaseOrderModified || _vm.brandAddressModified
    },
    on: {
      "change": _vm.updateName
    },
    model: {
      value: _vm.brand.name,
      callback: function ($$v) {
        _vm.$set(_vm.brand, "name", $$v);
      },
      expression: "brand.name"
    }
  }) : _vm._e(), _c('v-card-actions', [_c('v-btn', {
    staticClass: "text-none mr-2",
    attrs: {
      "color": "error",
      "loading": _vm.loading,
      "disabled": !_vm.brandNameModified || _vm.loading,
      "ripple": false
    },
    on: {
      "click": _vm.getBrand
    }
  }, [_c('v-icon', {
    staticClass: "mr-1",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fa fa-ban ")]), _vm._v(" " + _vm._s(_vm.$t('Cancel')) + " ")], 1), _c('v-btn', {
    staticClass: "text-none",
    attrs: {
      "color": "success",
      "loading": _vm.loading,
      "disabled": !_vm.brandNameModified || _vm.loading,
      "ripple": false
    },
    on: {
      "click": _vm.modifyBrand
    }
  }, [_c('v-icon', {
    staticClass: "mr-1",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fa fa-check ")]), _vm._v(" " + _vm._s(_vm.$t('Submit')) + " ")], 1)], 1)], 1)], 1), _c('v-card-title', [_vm._v(_vm._s(_vm.$t('EditBrandDefaultPurchaseOrder')))]), _c('v-card-text', [_c('v-form', [_vm.brand ? _c('v-text-field', {
    attrs: {
      "type": "text",
      "outlined": "",
      "label": _vm.$t('PurchaseOrder'),
      "loading": _vm.loading,
      "disabled": _vm.loading || _vm.brandNameModified || _vm.brandAddressModified,
      "maxlength": 29,
      "counter": ""
    },
    on: {
      "change": _vm.updatePurchaseOrder
    },
    model: {
      value: _vm.brand.purchaseOrder,
      callback: function ($$v) {
        _vm.$set(_vm.brand, "purchaseOrder", $$v);
      },
      expression: "brand.purchaseOrder"
    }
  }) : _vm._e()], 1), _c('v-card-actions', [_c('v-btn', {
    staticClass: "text-none mr-2",
    attrs: {
      "color": "error",
      "loading": _vm.loading,
      "disabled": !_vm.brandPurchaseOrderModified || _vm.loading,
      "ripple": false
    },
    on: {
      "click": _vm.getBrand
    }
  }, [_c('v-icon', {
    staticClass: "mr-1",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fa fa-ban ")]), _vm._v(" " + _vm._s(_vm.$t('Cancel')) + " ")], 1), _c('v-btn', {
    staticClass: "text-none",
    attrs: {
      "color": "success",
      "loading": _vm.loading,
      "disabled": !_vm.brandPurchaseOrderModified || _vm.loading,
      "ripple": false
    },
    on: {
      "click": _vm.modifyPurchaseOrder
    }
  }, [_c('v-icon', {
    staticClass: "mr-1",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fa fa-check ")]), _vm._v(" " + _vm._s(_vm.$t('Submit')) + " ")], 1)], 1)], 1), _c('v-card-title', [_vm._v(" " + _vm._s(_vm.$t('PaymentMethodFilledIn')) + " ")]), _c('v-card-text', [_c('v-icon', {
    staticClass: "mr-2",
    attrs: {
      "large": "",
      "color": _vm.loadingPaymentMethodPresence ? 'info' : _vm.hasDefaultPaymentMethod ? 'success' : 'error'
    }
  }, [_vm._v(" fas fa-credit-card ")]), _vm.loadingPaymentMethodPresence ? _c('v-icon', {
    staticClass: "mr-2"
  }, [_vm._v(" fas fa-circle-notch fa-spin ")]) : _vm._e(), _vm.loadingPaymentMethodPresence ? _c('span', [_vm._v(" " + _vm._s(_vm.$t('Loading')) + " ")]) : _vm.hasDefaultPaymentMethod ? _c('span', [_vm._v(" " + _vm._s(_vm.$t('BrandHasDefaultPaymentMethod')) + " ")]) : _c('span', [_vm._v(" " + _vm._s(_vm.$t('BrandDoesNotHaveDefaultPaymentMethod')) + " ")])], 1)], 1), _c('v-col', {
    staticClass: "col-12 col-md-6"
  }, [_c('v-card', {
    attrs: {
      "flat": ""
    }
  }, [_c('v-card-title', [_vm._v(_vm._s(_vm.$t('EditBrandBillingAddress')))]), _c('v-card-text', [_vm.brand ? _c('formAddress', {
    ref: "addressForm",
    attrs: {
      "disabled": _vm.loading || _vm.brandNameModified || _vm.brandPurchaseOrderModified,
      "address": _vm.brand.address,
      "is-valid-v-a-t": _vm.isValidVAT,
      "restricted-country-list": [],
      "required-company": true,
      "required-zip-code": true,
      "required-phone-number": true,
      "required-v-a-tnumber": true,
      "allow-to-set-not-applicable-v-a-t": true
    },
    on: {
      "validateVAT": _vm.validateVAT,
      "updated": _vm.updateAddress
    }
  }) : _vm._e(), _c('v-card-actions', [_c('v-btn', {
    staticClass: "text-none mr-2",
    attrs: {
      "color": "error",
      "loading": _vm.loading,
      "disabled": !_vm.brandAddressModified || _vm.loading,
      "ripple": false
    },
    on: {
      "click": _vm.getBrand
    }
  }, [_c('v-icon', {
    staticClass: "mr-1",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fa fa-ban ")]), _vm._v(" " + _vm._s(_vm.$t('Cancel')) + " ")], 1), _c('v-btn', {
    staticClass: "text-none",
    attrs: {
      "color": "success",
      "loading": _vm.loading,
      "disabled": !_vm.brandAddressModified || _vm.loading,
      "ripple": false
    },
    on: {
      "click": _vm.modifyBrandAddress
    }
  }, [_c('v-icon', {
    staticClass: "mr-1",
    attrs: {
      "small": ""
    }
  }, [_vm._v(" fa fa-check ")]), _vm._v(" " + _vm._s(_vm.$t('Submit')) + " ")], 1)], 1)], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }