<template>
  <v-card
    :disabled="disabled"
    flat
    class="px-5 ml-5"
  >
    <v-row>
      <v-col class="col-12 col-md-6">
        <v-card-title>{{ $t('EditBrandInformations') }}</v-card-title>
        <v-card-text>
          <v-form ref="brandForm">
            <v-text-field
              v-if="brand"
              v-model="brand.name"
              :label="$t('name')"
              :rules="[(value) => !!value.trim() || this.$t('nameRules')]"
              outlined
              required
              :loading="loading"
              :disabled="loading || brandPurchaseOrderModified || brandAddressModified"
              @change="updateName"
            >
            </v-text-field>
            <v-card-actions>
              <v-btn
                color="error"
                class="text-none mr-2"
                :loading="loading"
                :disabled="!brandNameModified || loading"
                :ripple="false"
                @click="getBrand"
              >
                <v-icon small class="mr-1">
                  fa fa-ban
                </v-icon>
                {{ $t('Cancel') }}
              </v-btn>
              <v-btn
                color="success"
                class="text-none"
                @click="modifyBrand"
                :loading="loading"
                :disabled="!brandNameModified || loading"
                :ripple="false"
              >
                <v-icon small class="mr-1">
                  fa fa-check
                </v-icon>
                {{ $t('Submit') }}
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card-text>
        <!---
          ---
          --- PURCHASE ORDER
          ---
          -->
        <v-card-title>{{ $t('EditBrandDefaultPurchaseOrder') }}</v-card-title>
        <v-card-text>
          <v-form>
            <v-text-field
              v-if="brand"
              v-model="brand.purchaseOrder"
              type="text"
              outlined
              :label="$t('PurchaseOrder')"
              :loading="loading"
              :disabled="loading || brandNameModified || brandAddressModified"
              @change="updatePurchaseOrder"
              :maxlength="29"
              counter
            >
            </v-text-field>
          </v-form>
          <v-card-actions>
            <v-btn
              color="error"
              class="text-none mr-2"
              :loading="loading"
              :disabled="!brandPurchaseOrderModified || loading"
              :ripple="false"
              @click="getBrand"
            >
              <v-icon small class="mr-1">
                fa fa-ban
              </v-icon> {{ $t('Cancel') }}
            </v-btn>
            <v-btn
              color="success"
              class="text-none"
              :loading="loading"
              :disabled="!brandPurchaseOrderModified || loading"
              :ripple="false"
              @click="modifyPurchaseOrder"
            >
              <v-icon small class="mr-1">
                fa fa-check
              </v-icon>
              {{ $t('Submit') }}
            </v-btn>
          </v-card-actions>
        </v-card-text>
        <v-card-title>
          {{ $t('PaymentMethodFilledIn') }}
        </v-card-title>
        <v-card-text>
          <v-icon
            large
            :color="loadingPaymentMethodPresence ? 'info' : hasDefaultPaymentMethod ? 'success' : 'error'"
            class="mr-2"
          >
            fas fa-credit-card
          </v-icon>
          <v-icon v-if="loadingPaymentMethodPresence" class="mr-2">
            fas fa-circle-notch fa-spin
          </v-icon>
          <span v-if="loadingPaymentMethodPresence">
            {{ $t('Loading') }}
          </span>
          <span v-else-if="hasDefaultPaymentMethod">
            {{ $t('BrandHasDefaultPaymentMethod') }}
          </span>
          <span v-else>
            {{ $t('BrandDoesNotHaveDefaultPaymentMethod') }}
          </span>
        </v-card-text>
      </v-col>
      <!--
        ---
        ---
        ---- BILLING ADDRESS
        ---
        ---
        -->
      <v-col class="col-12 col-md-6">
        <v-card flat>
          <v-card-title>{{ $t('EditBrandBillingAddress') }}</v-card-title>
          <v-card-text>
            <formAddress
              v-if="brand"
              :disabled="loading || brandNameModified || brandPurchaseOrderModified"
              :address="brand.address"
              ref="addressForm"
              :is-valid-v-a-t="isValidVAT"
              :restricted-country-list="[]"
              :required-company="true"
              :required-zip-code="true"
              :required-phone-number="true"
              :required-v-a-tnumber="true"
              :allow-to-set-not-applicable-v-a-t="true"
              @validateVAT="validateVAT"
              @updated="updateAddress"
            >
            </formAddress>
            <v-card-actions>
              <v-btn
                color="error"
                class="text-none mr-2"
                :loading="loading"
                :disabled="!brandAddressModified || loading"
                :ripple="false"
                @click="getBrand"
              >
                <v-icon small class="mr-1">
                  fa fa-ban
                </v-icon>
                {{ $t('Cancel') }}
              </v-btn>
              <v-btn
                color="success"
                class="text-none"
                :loading="loading"
                :disabled="!brandAddressModified || loading"
                :ripple="false"
                @click="modifyBrandAddress"
              >
                <v-icon small class="mr-1">
                  fa fa-check
                </v-icon>
                {{ $t('Submit') }}
              </v-btn>
            </v-card-actions>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { EventBus, ApiErrorParser, FormAddress } from '@cloudmanufacturingtechnologies/portal-components';

const i18nData = require('./pageBrandGeneral.i18n.json');

export default {
  name: 'PageBrandGeneral',
  components: { FormAddress },
  i18n: {
    messages: i18nData,
    dateTimeFormats: i18nData,
    numberFormats: i18nData,
  },
  data() {
    return {
      loading: false,
      disabled: true,
      brand: null,
      brandNameModified: false,
      brandPurchaseOrderModified: false,
      brandAddressModified: false,
      isValidVAT: false,
      loadingPaymentMethodPresence: false,
      hasDefaultPaymentMethod: false,
    };
  },
  created() {
    /**
     * GET BRAND
     */
    this.getBrand();
    this.getBrandDefaultPaymentMethodPresence();
  },
  methods: {
    getBrand() {
      /**
       * GET BRAND
       */
      this.loading = true;
      this.$apiInstance
        .getAdminBrand(this.$route.params.brandUUID)
        .then(
          (brandData) => {
            if(!brandData.address) {
              brandData.address = new this.$BcmModel.Address();
            }
            this.brand = brandData;
            this.name = null,
            this.purchaseOrder = null;
            this.address = null,
            this.disabled = false;
            this.brandNameModified = false;
            this.brandPurchaseOrderModified = false;
            this.brandAddressModified = false;
          },
          (error) => {
            this.$notification.notify('ERROR',this.$t(ApiErrorParser.parse(error)));
          }
        )
        .finally(() => {
          this.loading = false;
        });
    },
    getBrandDefaultPaymentMethodPresence() {
      this.loadingPaymentMethodPresence = true;
      this.$apiInstance.getAdminBrandDefaultPaymentMethodPresence(
        this.$route.params.brandUUID
      ).then(data => {
        this.hasDefaultPaymentMethod = data;
      }, (error) => {
        this.$notification.notify('ERROR', ApiErrorParser.parse(error));
      }).finally(() => {
        this.loadingPaymentMethodPresence = false;
      });
    },
    /**
     * MODIFY BRAND
     */
    modifyBrand() {
      if (this.$refs.brandForm.validate()) {
        this.loading = true;
        const modifyBrandBody = new this.$BcmModel.ModifyBrandBody(
          this.brand.name
        );
        this.$apiInstance
          .modifyBrand(this.$route.params.brandUUID, modifyBrandBody)
          .then(
            (modifyBrandData) => {
              this.getBrand();
              this.$notification.notify('SUCCESS', this.$t('NameSuccessfullyModified'));
            },
            (error) => {
              /**
               * ERROR MODIFY BRAND
               */
              this.$notification.notify('ERROR',this.$t(ApiErrorParser.parse(error)));
            }
          )
          .finally(() => {
            this.loading = false;
          });
      }
    },
    /**
     * Validate VAT
     */
    async validateVAT(countryCode, vat) {
      this.isValidVAT = await this.$apiInstance.validateEuropeanVAT(countryCode, vat)
        .then((data) => {
          return true;
        }, (error) => {
          return false;
        });
    },
    /**
     * MODIFY BRAND ADDRESS
     */
    modifyBrandAddress() {
      if (this.$refs.addressForm.validate()) {
        this.loading = true;
        const address = Object.assign(
          new this.$BcmModel.Address(),
          this.brand.address
        );
        if(!address.VATnumber) { // If null, VAT is not added and will be consider to configured later
          delete address.VATnumber;
        }
        this.$apiInstance
          .modifyBrandAddress(this.$route.params.brandUUID, address)
          .then(
            (brandAddressData) => {
              this.getBrand();
              /**
               * Component BeelseNotifications used
               */
              this.$notification.notify('SUCCESS',this.$t('AddressSuccessfullyModified'));
            },
            (error) => {
              /**
               * ERROR MODIFY BRAND ADDRESS
               */
              this.$notification.notify('ERROR',this.$t(ApiErrorParser.parse(error)));
            }
          )
          .finally(() => {
            this.loading = false;
          });
      }
    },
    /**
     * MODIFY PURCHASE ORDER
     */
    modifyPurchaseOrder() {
      this.loading = true;
      const modifyBrandPurchaseOrderBody = new this.$BcmModel.ModifyBrandPurchaseOrderBody(
        this.brand.purchaseOrder
      );
      this.$apiInstance
        .modifyBrandPurchaseOrder(
          this.$route.params.brandUUID,
          modifyBrandPurchaseOrderBody
        )
        .then(
          (purchaseOrderData) => {
            this.getBrand();
            /**
             * Component BeelseNotifications used
             */
            this.$notification.notify('SUCCESS',this.$t('PurchaseOrderSuccessfullyModified'));
            
          },
          (error) => {
            /**
             * ERROR MODIFY PURCHASE ORDER
             */
            this.$notification.notify('ERROR',this.$t(ApiErrorParser.parse(error)));
          }
        )
        .finally(() => {
          this.loading = false;
        });
    },
    /**
     * ChECK IF BRAND NAME CHANGE
     */
    updateName(name) {
      this.brand.name = name;
      this.brandNameModified = true;
    },
    /**
     * ChECK IF PURCHASE ORDER CHANGE
     */
    updatePurchaseOrder(purchaseOrder) {
      this.brand.purchaseOrder = purchaseOrder;
      this.brandPurchaseOrderModified = true;
    },
    /**
     * ChECK IF BILLING ADDRESS CHANGE
     */
    updateAddress(address) {
      this.brand.address = address;
      this.brandAddressModified = true;
    },

  },
};
</script>